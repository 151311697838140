import { template as template_5e1816fcd1b04015886e1c1a4de4878d } from "@ember/template-compiler";
const FKLabel = template_5e1816fcd1b04015886e1c1a4de4878d(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
